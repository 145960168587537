import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom"
import {isMobile} from 'react-device-detect';

const SelectBox = (props) => {

    const navigate = useNavigate();

    const {auto, my, modelObj} = props;
    let { autox, modelx, modely } = useParams();
    console.log('component log: '+autox +'/'+ modelx+'/'+ modely);

    const [autoName,setAuto] = useState(autox);
    const [modelList,setModelList] = useState([]);
    const [modelName,setModel] = useState(modelx);
    const [myList,setMYList] = useState([]);
    const [myDetail,setDetail] = useState([]);

    const [webMyDetail,setWebDetail] = useState(modelObj);

    const [showMyList, setShowMYList] = useState((modelName && !modely ? true : false));
    const [showDetail, setShowDetail] = useState((modely ? true : false));
      
    const autos = auto.auto;
    const mys = my.model;


    const capitalizeFirst = str => {
        return str.toUpperCase();
    };

    const strLower = str => {
        return str.toLowerCase();
    };

    const getSelectedText = (el) => {
        if (el.selectedIndex === -1) {
            return null;
        }
        return el.options[el.selectedIndex].text;
    }

    const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

    const getModel = (e)=>{
        //e.preventDefault();
        const brandID = e.target.value;
        //console.log(brandID);
        setShowMYList(false);

        if(brandID!==""){
            const getModelData = autos && autos.find(model=>model.brand===strLower(brandID)).models;
            setModelList(getModelData);
            setAuto(brandID);
            const ind = e.nativeEvent.target.selectedIndex;
            let title = e.nativeEvent.target[ind].text;
            //console.log('native: ' + e.nativeEvent.target[ind].text);
            
            window.history.replaceState(null, capitalizeFirst(title), "/"+strLower(title))
        }else{
            setModelList('');
            setAuto('');

            window.history.replaceState(null, "ตารางขั้วหลอดไฟรถยนต์", "/")
        }

        setModel('');
        setMYList([]);
        setShowDetail(false);
        setWebDetail('');
    }

    const getModelYear = (e)=>{
        const modelName = e.target.value;
        setModel(modelName);
        setMYList([]);
        setShowDetail(false);
        setWebDetail('');
        setShowMYList(false);

        const selectBrand = document.querySelector('#auto-box');
        const text = getSelectedText(selectBrand);
        const ind = e.nativeEvent.target.selectedIndex;
        let title = e.nativeEvent.target[ind].text;

        if(modelName!==""){
            const getMYList = mys && mys.find(model=> strLower(model.model) === strLower(modelName) ).my;
            setMYList(getMYList);
            setTimeout(()=>{
                setShowMYList(true);
            },[200])
            

            window.history.replaceState(null, capitalizeFirst(text) +":"+ capitalizeFirst(title), "/"+strLower(text)+"/"+strLower(title));
        }else{
            
            window.history.replaceState(null, capitalizeFirst(text) +":"+ capitalizeFirst(title), "/"+strLower(text));
        }

     }

     const getDetail = (id, parent_id, my) => {
        //e.preventDefault();
        if(id!==""){
            //console.log(id);
            const modelArr = mys && mys.find(model=>model.id===parent_id).my;
            const getMYDetail = modelArr && modelArr.find(model=>model.id===id);
            setWebDetail([]);
            setDetail(getMYDetail);
            setShowDetail(true);
            setShowMYList(false);

            let selectBrand = document.querySelector('#auto-box');
            const text = getSelectedText(selectBrand);
            let selectModel = document.querySelector('#model-box');
            const model = getSelectedText(selectModel);
            window.history.replaceState(null, capitalizeFirst(text) +":"+ capitalizeFirst(model), "/"+strLower(text)+"/"+strLower(model)+"/"+strLower(my));
        }
     }

     const goBack = (e) =>{
        console.log(e);
        navigate(e);
     }

     //console.log(mys);

    return (
        <div>
        <div className="subscribe2">
        <select className="form-control" id="auto-box" onChange={(e)=>getModel(e)} value={autoName}>
            <option value="">-- เลือกยี่ห้อรถ --</option>
            {
                autos && autos.map( data => {
                    return (
                        <option value={data.brand} key={data.id}>{capitalizeFirst(data.brand)}</option>
                    )
                })
            }
        </select>
        <select className="form-control" id="model-box" onChange={(e)=>getModelYear(e)} value={modelName}>
            <option value="">-- เลือกรุ่น --</option>
            {
                modelList && modelList.map(model => {
                    return ( 
                        <option value={strLower(model.model)} key={model.id}>{capitalizeFirst(model.model)}</option>
                    )   
                })
            }

            {(autoName && modelList.length < 1) && 
            
                autos.find(model=>model.brand === strLower(autox)).models.map(modd =>{
                    return (
                        <option value={strLower(modd.model)} key={modd.id}>{capitalizeFirst(modd.model)}</option>
                    )
                })
            
            }
        
            </select>

        </div>

        <div id="model__list" 
        style={{
            opacity: !showMyList ? "0" : "1",
            transition: "all .2s",
            visibility: !showMyList ? "hidden" : "visible",
            height: !showMyList ? "0" : "inherit"
        }}>
        { (myList && myList.length)> 0 &&
            <ul id="model-list" className="flex-gal" 
            style={{
                opacity: !showMyList ? "0" : "1",
                transition: "all .1s",
                visibility: !showMyList ? "hidden" : "visible",
                height: !showMyList ? "0" : "inherit"
                }}>
                {myList && myList.map( my =>{
                    return(
                        <li key={my.id}>
                            <span onClick={()=>getDetail(my.id, my.parent_id, my.alias)}>
                            <figure><img src={'https://access.tapopo.net/car/model'+ (isMobile ? my.photo_m : my.photo)} alt={my.name}/>
                            <figcaption>{my.name}</figcaption></figure>
                            </span>
                        </li>
                    )
                })
            }
            </ul>
        }

        {( myList.length < 1 && modelName ) && 
            <ul id="model-list" className="flex-gal"
            style={{
                opacity: !showMyList ? "0" : "1",
                transition: "all .1s",
                visibility: !showMyList ? "hidden" : "visible",
                height: !showMyList ? "0" : "inherit"
                }}>
            { mys && mys.find(model=> strLower(model.model) === strLower(modelName)).my.map( my =>{
                    return(
                        <li key={my.id}>
                            <span onClick={()=>{getDetail(my.id, my.parent_id, my.alias); setShowMYList(false)}}>
                            <figure><img src={'https://access.tapopo.net/car/model'+ (isMobile ? my.photo_m : my.photo)} alt={my.name}/>
                            <figcaption>{my.name}</figcaption></figure>
                            </span>
                        </li>
                    )
                })
            }
            </ul>
        }
        </div>

        { myDetail.name && 
            <div style={{display: showDetail ? 'block' : 'none'}} id="socket-detail">
            <h2>{myDetail.pre_name}<br/><small>{myDetail.name}</small></h2>
            <h3><span onClick={()=> {
                setShowMYList(true);
                setShowDetail(false);
                window.history.replaceState(null, myDetail.pre_name, "/"+autoName+"/"+modelName);}}><u>Back</u></span></h3>
            <figure><img src={'https://access.tapopo.net/car/model'+myDetail.photo} alt={myDetail.name}/></figure>
            {renderHTML(myDetail.text)}
            <h3><span onClick={()=>{
                setShowMYList(true);
                setShowDetail(false);
                window.history.replaceState(null, myDetail.pre_name, "/"+autoName+"/"+modelName);
             }}><u>Back</u></span></h3>
            </div>
        }

        { (webMyDetail && modely) &&
            <div style={{display: showDetail ? 'block' : 'none'}} id="socket-detail">
            <h2>{webMyDetail.pre_name}<br/><small>{webMyDetail.name}</small></h2>
            <h3><span onClick={()=>{
                goBack('/'+autoName+'/'+modelName);
                setShowDetail(false);
                setShowMYList(true);

            }}><u>Back</u></span></h3>
            <figure><img src={'https://access.tapopo.net/car/model'+webMyDetail.photo} alt={webMyDetail.name}/></figure>
            {renderHTML(webMyDetail.text)}
            <h3><span onClick={()=>{
                goBack('/'+autoName+'/'+modelName);
                setShowDetail(false);
                setShowMYList(true);

            }}><u>Back</u></span></h3>
            </div>
        }

        </div>
        
    )
}

export default SelectBox;
