import React from 'react';
import { Route, Routes, useLocation } from "react-router-dom";
import './App.css';
import SelectBox from './component/Select01';
import auto from "./component/json/auto-model-list.json";
import my from "./component/json/auto-my-list.json";
//import logo from "../public/logo192.png";

const Header = () => <h1><img src={process.env.PUBLIC_URL + "/logo192.png"} alt="powered by React.js" /> ขั้วหลอดไฟรถ</h1>;

function App() {

  const strLower = str => {
    return str.toLowerCase();
  };

  const location = useLocation();
  const paramArr = location.pathname.split("/");
  let autox = paramArr[1];
  let modelx = paramArr[2];
  let modely = paramArr[3];

  console.log('app log: '+autox +'/'+ modelx+'/'+ decodeURI(modely));

  if(modely!=="" && modely!==undefined){
    const mys = my.model.find(model=> strLower(model.model) === modelx).my;
    var getMYDetail = mys && mys.find(model=> strLower(decodeURI(model.alias)) === strLower(decodeURI(modely)));
  }

  return (
    <div className="App">
      <Header/>
      <Routes>
        <Route path="/" element={<SelectBox auto={auto} my={my} modelObj={getMYDetail} />} />
        <Route path="/:autox" element={<SelectBox auto={auto} my={my} modelObj={getMYDetail} />} />
        <Route path="/:autox/:modelx" element={<SelectBox auto={auto} my={my} modelObj={getMYDetail} />} />
        <Route path="/:autox/:modelx/:modely" element={<SelectBox auto={auto} my={my} modelObj={getMYDetail}/>} />
      </Routes>
    </div>
  );
}

export default App;
